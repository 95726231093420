import React from 'react';

import Home from './views/Home';
import {Layout} from "./components/shared/Layout";

const App: React.FC = () => {
  return (
    <Layout>
      <Home />
    </Layout>
  );
};

export default App;
