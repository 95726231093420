import React, {ReactNode} from "react";
import GoogleAd from "./GoogleAd";
import styled from "styled-components";
import {BACKGROUND_COLOR, PRIMARY_COLOR, TEXT_COLOR} from "../styles";

const MainContent = styled.div`
  width: 100%;
  padding: 0 10px; // This will ensure a little space on the sides on mobile.

  @media (min-width: 961px) {
    max-width: 760px;
    padding: 0;
  }
`;

const AdContainer = styled.div`
  flex: 1; // This will allow the ad containers to take up the available space on either side
  display: none; // Initially hidden

  @media (min-width: 961px) { // When screen width is more than 760px plus some margin
    display: block; // Show the ad containers
  }
`;

const AppContainer = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100vh;  // Make sure it always takes up the full viewport height
`;

const MobileAdContainer = styled.div`
  display: none; // By default, the mobile ad is hidden

  @media (max-width: 960px) {  // When the screen width is less than or equal to 960px
    display: block;  // Show the mobile ad container
    margin: 20px 0;  // Some margin to space it out from content and footer
  }
`;

const FooterContainer = styled.footer`
  padding: 0.8em;
  background-color: ${BACKGROUND_COLOR};
  color: white;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const LinkContainer = styled.div`
  display: flex;
  gap: 12px; // This provides spacing between the links
`;

const Copyright = styled.p`
  margin-top: 20px;
  font-size: 0.8em;
  text-align: center;
`;

const FooterLink = styled.a`
  margin: 0 15px;
  color: #fff;
  font-weight: 500;  // Making the links a bit bolder
  text-decoration: none;
  position: relative;  // To position the hover effect

  // Adding a hover effect to the links for better interactivity
  &:hover {
    color: ${PRIMARY_COLOR};  // Changing color on hover to your text color for contrast
  }

  &:after {
    content: '';
    position: absolute;
    bottom: -2px;
    left: 0;
    right: 0;
    height: 2px;
    background-color: ${TEXT_COLOR};  // Using your text color for the line
    transform: scaleX(0);
    transform-origin: center;
    transition: transform 0.25s ease;
  }

  &:hover:after {
    transform: scaleX(1);
  }
`;

interface LayoutProps {
	children: ReactNode;
}
export const Layout: React.FC<LayoutProps> = ({ children }) => {
	const currentYear = new Date().getFullYear();
	return (
		<AppContainer>
			<div style={{ display: 'flex', flex: '1 0 auto' }}>
				<AdContainer>
					<GoogleAd />
				</AdContainer>
				<MainContent>
					{children}
				</MainContent>
				<AdContainer>
					<GoogleAd />
				</AdContainer>
			</div>
			<MobileAdContainer>
				<GoogleAd />
			</MobileAdContainer>
			<FooterContainer>
				<LinkContainer>
					<FooterLink href="https://nagaofactory.jp" target="_blank" rel="noopener noreferrer">About Us</FooterLink>
					<FooterLink href="https://www.nagaofactory.jp/pixelpolish-terms-of-use" target="_blank" rel="noopener noreferrer">Terms of Use</FooterLink>
					<FooterLink href="https://www.nagaofactory.jp/pixelpolish-privacy-policy" target="_blank" rel="noopener noreferrer">Privacy Policy</FooterLink>
				</LinkContainer>
				<Copyright>PixelPolish©{currentYear}</Copyright>
			</FooterContainer>
		</AppContainer>
	);
};
