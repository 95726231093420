import React, { useEffect } from 'react';

const GoogleAd: React.FC = () => {

	useEffect(() => {
		// Initialize the ad after the component mounts
		// @ts-ignore
		if (typeof window !== 'undefined' && window.adsbygoogle) {
			// @ts-ignore
			if (!window.adsbygoogle.loaded) {
				// @ts-ignore
				window.adsbygoogle.push({});
			}
		}
	}, []);

	// return null;
	return (
		<ins
			className="adsbygoogle"
			style={{ display: "block" }}
			data-ad-client="ca-pub-4368116494232379"
			data-ad-slot="8126947279"
			data-ad-format="auto"
			data-full-width-responsive="true"
		></ins>
	);
}

export default GoogleAd;
