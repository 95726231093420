// firebaseClient.ts
import { initializeApp } from 'firebase/app';
import { getAuth, Auth } from "firebase/auth";
import {getAnalytics} from "firebase/analytics";
import {getFirestore} from "firebase/firestore";

const firebaseConfig = {
	apiKey: "AIzaSyAwIz5u8J55DraSDM8YCpYm8AoJmP9HQm0",
	authDomain: "kirei-a42fb.firebaseapp.com",
	projectId: "kirei-a42fb",
	storageBucket: "kirei-a42fb.appspot.com",
	messagingSenderId: "97083576522",
	appId: "1:97083576522:web:789051d6ba595e5f002433",
	measurementId: "G-T3QD5X2QXD"
};

const app = initializeApp(firebaseConfig);

const analytics = getAnalytics(app);
const auth: Auth = getAuth(app);
const db = getFirestore(app);

export { app, analytics, auth, db };
