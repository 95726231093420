import { lighten } from 'polished';
import styled from 'styled-components';
import {BORDER_COLOR, PRIMARY_COLOR, TEXT_COLOR, BUTTON_TEXT_COLOR} from "./styles";

interface UploadAreaProps {
	isDragOver: boolean;
}

export const Container = styled.div`
  width: 100%;
  max-width: 760px;
  margin: 40px auto;  // Increased vertical margin
  box-sizing: border-box;  // This ensures padding is included in the element's total width

  @media (max-width: 768px) {
    padding: 0 10px; // Apply padding on smaller screens
  }
`;

export const StyledH1 = styled.h1`
  font-size: 2.5em;
  font-weight: bold;
  color: #333;
  text-align: center;
  margin-bottom: 0.5em;

  /* Gradient Effect */
  background: -webkit-linear-gradient(45deg, #ff9a9e, #fad0c4);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
`;

export const StyledH2 = styled.h2`
  font-size: 1.5em;
  font-weight: 600;
  color: #555;
  text-align: center;
  margin-bottom: 1em;

  /* Decorative Element */
  position: relative;

  //&:before {
  //  content: '';
  //  position: absolute;
  //  left: 50%;
  //  transform: translateX(-50%);
  //  bottom: -10px;
  //  height: 3px;
  //  width: 60px;
  //  background-color: #f9a8d4;
  //}
`;

export const StyledParagraph = styled.p`
  font-size: 1.1em;
  line-height: 1.5;
  color: #4a4a4a; // A slightly muted color for better readability
  text-align: center; // Centering the text can make it stand out
  margin-bottom: 1.5em; // Giving it more space to breathe
  font-weight: 500; // A bit bolder than regular text but not as bold as headings
  text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.1); // A subtle shadow to lift the text

  max-width: 90%; // To prevent it from stretching too wide on larger screens
  margin-left: auto;
  margin-right: auto;
`;

export const ImageContainer = styled.div`
  width: 100%; // Ensure the container takes full width
  overflow: hidden; // Prevent content from spilling over
`;

export const PreviewImage = styled.img`
  width: 760px;
  max-width: 100%; // ensure responsiveness
  display: block;
  height: auto;
  border-radius: 8px;
`;

export const UploadArea = styled.div<UploadAreaProps>`
  width: 760px;
  max-width: 100%; // ensure responsiveness
  border: 1px dashed ${BORDER_COLOR};
  min-height: 200px;
  position: relative;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column; // To align items vertically
  gap: 10px; // Space between child items
  border-radius: 8px;
  background-color: ${({ isDragOver }) => (isDragOver ? '#f0f0f0' : 'transparent')};
  border: ${({ isDragOver }) => (isDragOver ? `1px dashed ${PRIMARY_COLOR}` : `1px dashed ${BORDER_COLOR}`)};

  @media (max-width: 768px) {
    width: 100%;
  }
	
	
`;

export const DownloadButton = styled.a`
  display: inline-flex;
  align-items: center; /* This will vertically center the child content */
  margin-right: 20px;
	margin-left: 20px;
  cursor: pointer;
`;

export const UploadIcon = styled.img`
  width: 32px; // Adjust as needed
  height: 32px;
  color: ${PRIMARY_COLOR};
`;

export const UploadText = styled.span`
  font-size: 16px;
  color: ${PRIMARY_COLOR};
`;

export const DragText = styled.span`
  font-size: 14px;
  color: ${TEXT_COLOR};
`;

export const HiddenInput = styled.input`
  display: none;
`;
export const ActionButton = styled.button`
  display: block;
  width: 100%;
	height: 50px;
	border: none;
  margin-top: 20px;
  padding: 10px 0;
  font-size: 16px;
	cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'pointer')}; // Use not-allowed cursor if disabled
  border-radius: 8px; // Added corner radius
  background-color: ${({ disabled, theme }) => (disabled ? '#e0e0e0' : PRIMARY_COLOR)}; // Use primary color if enabled
  color: ${BUTTON_TEXT_COLOR}; // Set text color to white
  opacity: ${({ disabled }) => (disabled ? 0.5 : 1)};
  transition: background-color 0.3s, opacity 0.3s; // Added for smooth color change
	&:hover {
		background-color: ${({ disabled, theme }) => (disabled ? '#e0e0e0' : lighten(0.1, PRIMARY_COLOR))}; 
		// Lighten the primary color a bit on hover for a more interactive feel
	} 
	&:focus {
    outline: none; // Optionally remove the default focus outline if you find it unnecessary
  }
`;


export const LoadingOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);  /* semi-transparent black */
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;  /* ensure the overlay is on top */
`;