import axios from 'axios';

const apiClient = axios.create({
	// baseURL: 'http://localhost:8080/', // Your backend server URL
	baseURL: 'https://kirei-a42fb.as.r.appspot.com/', // Your backend server URL
});

export const uploadImage = async (image: any, token: string) => {
	const formData = new FormData();
	formData.append('image', image);
	formData.append('token', token);
	return apiClient.post('prediction/image', formData, {
		headers: {
			'Content-Type': 'multipart/form-data',
		},
	});
};

export default apiClient;
