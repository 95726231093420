import React, {useEffect, useRef, useState} from 'react';
import { saveAs } from 'file-saver';

import { uploadImage } from '../services/apiClient';
import {collection, doc, onSnapshot} from "firebase/firestore";
import {auth, db} from "../services/firebaseClient";
import {signInAnonymously, User} from "firebase/auth";
import {
	ActionButton,
	Container,
	DownloadButton,
	DragText,
	HiddenInput, ImageContainer, LoadingOverlay, PreviewImage, StyledH1, StyledH2, StyledParagraph,
	UploadArea,
	UploadIcon,
	UploadText
} from "../components/home";
import uploadIcon from "../assets/upload.svg";
import downloadIcon from "../assets/download.svg";
import LoadingView from "../components/shared/LoadingView";

const Home: React.FC = () => {
	const [image, setImage] = useState<File | null>(null);
	const [previewImage, setPreviewImage] = useState<string | null>(null);
	const [outputUrl, setOutputUrl] = useState<string | null>(null);
	const [loading, setLoading] = useState<boolean>(false);
	const [isDragOver, setIsDragOver] = useState<boolean>(false);
	const fileInputRef = useRef<HTMLInputElement | null>(null);


	useEffect(() => {
		auth.onAuthStateChanged(async (user: User | null) => {
			if (!user) {
				// Authenticate the user anonymously if not already authenticated
				await signInAnonymously(auth);
			} else {
				// Get the user's ID
				const userId = user.uid;
				const userRef = collection(db, 'users');
				const userDoc = doc(userRef, userId);

				// Listen for changes in the 'predictions' sub-collection.
				onSnapshot(userDoc, (doc) => {
					const userData = doc.data();
					if (userData) {
						if (userData.prediction.status === 'completed') {
							setOutputUrl(userData.prediction.output);
							setLoading(false);
						}
					}
				});
			}
		});

	}, [])

	const handleImageChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		const file = e.target.files ? e.target.files[0] : null;
		if (file) {
			setImage(file);
			const objectURL = URL.createObjectURL(file);
			setPreviewImage(objectURL);
		}
	};

	const handleSubmit = async (e: React.FormEvent) => {
		e.preventDefault();
		if (!image) return;

		setLoading(true);

		try {
			const token = await auth.currentUser?.getIdToken(true);
			if (token) {
				await uploadImage(image, token);
			}
		} catch (e: any) {
			console.error(e);
			setLoading(false);
		}
	};

	const handleDownload = async () => {
		try {
			const response = await fetch(outputUrl!);
			const blob = await response.blob();
			saveAs(blob, 'polished_image.jpg');  // You can adjust the filename as needed
		} catch (error) {
			console.error("Error downloading the image:", error);
		}
	}

	const handleReset = () => {
		setImage(null);
		setPreviewImage(null);
		setOutputUrl(null);
		setLoading(false);
	}

	const handleDragOver = (e: React.DragEvent<HTMLDivElement>) => {
		e.preventDefault(); // Necessary to allow for the drop
	};

	const handleDragEnter = (e: React.DragEvent<HTMLDivElement>) => {
		e.preventDefault();
		setIsDragOver(true); // Update the visual cue
	};

	const handleDragLeave = () => {
		setIsDragOver(false); // Update the visual cue
	};

	const handleDrop = (e: React.DragEvent<HTMLDivElement>) => {
		e.preventDefault();
		setIsDragOver(false); // Reset the visual cue

		const file = e.dataTransfer.files[0];
		if (file && (file.type === "image/jpeg" || file.type === "image/png")) {
			setImage(file);
			const objectURL = URL.createObjectURL(file);
			setPreviewImage(objectURL);
		}
	};

	const renderUploadArea = () => (
		<>
			<UploadArea
				onClick={() => fileInputRef.current?.click()}
				onDragOver={handleDragOver}
				onDragEnter={handleDragEnter}
				onDragLeave={handleDragLeave}
				onDrop={handleDrop}
				isDragOver={isDragOver}
			>
				{previewImage ? (
					<ImageContainer>
						<PreviewImage src={previewImage} alt="Selected" />
					</ImageContainer>
				) : (
					<>
						<UploadIcon src={uploadIcon} alt="Upload" />
						<UploadText>Upload a file</UploadText>
						<DragText>or drag and drop here</DragText>
					</>
				)}
				<HiddenInput type="file" accept="image/jpeg, image/png" onChange={handleImageChange} ref={fileInputRef} />
			</UploadArea>
			<ActionButton type="button" onClick={handleSubmit} disabled={!image}>
				Polish image
			</ActionButton>
		</>
	);

	const renderPolishedImage = () => (
		<>
			<PreviewImage src={outputUrl!} alt="Polished" />
			<div style={{ display: 'flex', justifyContent: 'space-between', marginTop: '20px' }}>
				<DownloadButton onClick={handleDownload}>
					<img src={downloadIcon} alt="Download" />
				</DownloadButton>
				<ActionButton onClick={() => handleReset()} style={{marginTop: "0px"}}>
					Upload another image
				</ActionButton>
			</div>
		</>
	);

	return (
		<Container>
			<StyledH1>PixelPolish</StyledH1>
			<StyledH2>Polish your image with only one click!</StyledH2>
			<StyledParagraph>Upload your photos. We will polish it for you.</StyledParagraph>
			{outputUrl ? renderPolishedImage() : renderUploadArea()}
			{loading && (
				<LoadingOverlay>
					<LoadingView />
				</LoadingOverlay>
			)}
		</Container>
	);
};

export default Home;
